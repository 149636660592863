const transferType = {
  "TYPES" : [
    {
      Value : 'ARRIVAL',
      Title : 'Geliş',
    },
    {
      Value : 'RETURN',
      Title : 'Dönüş',
    },],
};
export default transferType;
